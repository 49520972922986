import PropTypes from 'prop-types'
import React, { useEffect,useState } from "react"
import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"


import LoadingSpinner from "../../components/Common/LoadingSpinner"
import {postMethod } from "../../helpers/rest_api"
import logo from "../../assets/images/Monogram.png"

const ForgetPasswordPage = props => {

const [isLoading, setIsLoading] = useState(false);
const [isError, setError] = useState(false);
const [forgetSuccessMsg, setforgetSuccessMsg] = useState(false);
const [currentStep, setcurrentStep] = useState(1);
const [inputs, setInputs] = useState({is_active:1,username:'',password:'',con_password:'',otp:'',userid:''});

const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [name]: value.trim()}))
} 

  function handleValidSubmit(event, values) {
    setIsLoading(true)
    setError(false)
    setforgetSuccessMsg(false)
    if(currentStep ==1){
      
      postMethod("user/forPassvalidateUsername",inputs).then(function(response) {
        
        setIsLoading(false)
        if(response.status){
          setInputs(values => ({...values, ['userid']: response.userId}))
          setforgetSuccessMsg(response.message)  
          setcurrentStep(2)
        }else{
          setError(response.message)
        }
      })
    }
    if(currentStep ==2){
      
      if(inputs.otp){
      postMethod("user/validateOTP",inputs).then(function(response) {
        
        setIsLoading(false)
        if(response.status){
          setforgetSuccessMsg(response.message)  
          setcurrentStep(3)
        }else{
          setInputs(values => ({...values, ['otp']: ''}))
          setError(response.message)
        }
      })
    }else{
      setIsLoading(false)
      setInputs(values => ({...values, ['otp']: ''}))
      setError('Oops! Please enter OTP...')
    }
    }
    if(currentStep ==3){
      
      if(inputs.password === inputs.con_password){
      
      postMethod("user/updatePassword",inputs).then(function(response) {
        
        setIsLoading(false)
        if(response.status){
          setcurrentStep(4)
          setforgetSuccessMsg(response.message)  
        }else{
          setError(response.message)
        }
      })
    }else{
      setIsLoading(false)
      setInputs(values => ({...values, ['con_password']: ''}))
      setError('Oops! Confirm password is not matching...')
    }
    }
  }

  return (
    <React.Fragment>
      
      <div className="account-pages my-5 pt-sm-5">
        <Container>
        {isLoading &&<LoadingSpinner />}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Reset Password</h5>
                    <p className="text-white-50 mb-0">Re-Password.</p>

                    <a href="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-5">

                  <div className="p-2">
                    {isError ? (
                      <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        {isError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      
                      {currentStep ==1 &&<>
                      <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username or email"
                          className="form-control"
                          placeholder=""
                          type="text"
                          required
                          value={inputs.username}
                          onChange={handleChange}
                          
                        />
                      </div>
                      
                      <Row className="row mb-0">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Validate Username
                          </button>
                        </Col>
                      </Row>
                      </>}
                      
                      
                      {currentStep ==2 &&<>
                      <div className="mb-3">
                        <AvField
                          name="otp"
                          label="OTP"
                          className="form-control"
                          placeholder=""
                          type="text"
                          required
                          value={inputs.otp}
                          onChange={handleChange}
                          
                        />
                      </div>
                      
                      <Row className="row mb-0">
                        <Col className="col-6 text-start">
                          <a onClick={(e) => { setcurrentStep(1)}}>
                            <i className="mdi mdi-keyboard-backspace"></i> Back
                          </a>
                        </Col>
                        
                        <Col className="col-6 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Validate OTP
                          </button>
                        </Col>
                      </Row>
                      </>}
                      
                      {currentStep ==3 &&<>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="New Password"
                          className="form-control"
                          placeholder=""
                          type="password"
                          required
                          value={inputs.password}
                          onChange={handleChange}
                        />
                      </div>
                      
                      <div className="mb-3">
                        <AvField
                          name="con_password"
                          label="Conform Password"
                          className="form-control"
                          placeholder=""
                          type="password"
                          required
                          value={inputs.con_password}
                          onChange={handleChange}
                        />
                      </div>
                      
                      <Row className="row mb-0">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                      </>}
                    </AvForm>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                  <p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In
                                here</Link> </p>
                 <p>© {new Date().getFullYear()} Customizer <i
                  className="mdi mdi-heart text-danger"></i> by  oCode.co
                        </p>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
  
}



export default withRouter(ForgetPasswordPage)

