import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import BrandsList from "../watchc/Brands/List"
import ModalsList from "../watchc/Modals/List"
import MaterialsList from "../watchc/Materials/List"
import MaterialAttributes from "../watchc/MaterialsAttributes/Home"

import TShirtBrandsList from "../tshirts/Brands/List"
import TShirtModalsList from "../tshirts/Modals/List"
import TShirtProductsList from "../tshirts/Products/List"
import TShirtSidesList from "../tshirts/Sides/Home"
import TShirtLayersList from "../tshirts/Layers/List"
import TShirtTemplatesList from "../tshirts/Templates/List"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import ForgetPassword from "../pages/Authentication/ForgetPassword"
import Register from "../pages/Authentication/Register"
import Logout from "../pages/Authentication/Logout"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  //Products Brands
  { path: "/brands-list", component: BrandsList },
  { path: "/brand/:brandId/list-modal", component: ModalsList },
  { path: "/brand/:brandId/modal/:modalId/list-materials", component: MaterialsList },
  { path: "/brand/:brandId/modal/:modalId/material/:materialId/attributes", component: MaterialAttributes },
  
  { path: "/tshirt-brands", component: TShirtBrandsList },
  { path: "/tshirts-brand/:brandId/modals", component: TShirtModalsList },
  { path: "/tshirts-brand/:brandId/modal/:modalId/products", component: TShirtProductsList },
  { path: "/tshirts-brand/:brandId/modal/:modalId/product/:productId/sides", component: TShirtSidesList },
  { path: "/tshirts-brand/:brandId/modal/:modalId/product/:productId/side/:sideId/layers", component: TShirtLayersList },
  { path: "/tshirts-brand/:brandId/modal/:modalId/product/:productId/side/:sideId/templates", component: TShirtTemplatesList },
  

  // //profile
  { path: "/profile", component: UserProfile },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/brands-list" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/register", component: Register },
  
]

export { userRoutes, authRoutes }