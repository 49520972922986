import * as config  from "./config"

export async function uploadfile(functionname,data) {
const APIoptions = {
             method: 'POST',
             headers: {
                 'Accept': 'application/json',
                 Authorization: localStorage.getItem("OwnerKey"),
                 Authorizationtoken: localStorage.getItem("authorizationToken")
             },
             body: data
};
        
 return fetch(config.rest_api_host+'tshirt/'+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
  
         return result
  });       
 
}


export async function postMethod(functionname,data) {

const APIoptions = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                 Authorization: localStorage.getItem("OwnerKey"),
                 Authorizationtoken: localStorage.getItem("authorizationToken")
            }),
            body: JSON.stringify(data)
};
 
 console.log(config.rest_api_host+'tshirt/'+functionname)
 return fetch(config.rest_api_host+'tshirt/'+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         
  if(result.code == 401){
    window.location.replace('/login');
  }else{
    return result
  }
  });       
 
}


export async function getMethod(functionname) {
const APIoptions = {
            method: 'GET',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                 Authorization: localStorage.getItem("OwnerKey"),
                 Authorizationtoken: localStorage.getItem("authorizationToken")
            })
};
console.log(config.rest_api_host+'tshirt/'+functionname)
 return fetch(config.rest_api_host+'tshirt/'+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
  if(result.code == 401){
    window.location.replace('/login');
  }else{
    return result
  }
         
  });       
 
}


export async function deleteMethod(functionname,data) {
const APIoptions = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                 Authorization: localStorage.getItem("OwnerKey"),
                 Authorizationtoken: localStorage.getItem("authorizationToken")
            }),
            body: JSON.stringify(data)
};
        
 return fetch(config.rest_api_host+'tshirt/'+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         
  if(result.code == 401){
    window.location.replace('/login');
  }else{
    return result
  }
  });       
 
}


export async function updateStatusMethod(functionname,data) {
const APIoptions = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                 Authorization: localStorage.getItem("OwnerKey"),
                 Authorizationtoken: localStorage.getItem("authorizationToken")
            }),
            body: JSON.stringify(data)
};
        
 return fetch(config.rest_api_host+'tshirt/'+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         
  if(result.code == 401){
    window.location.replace('/login');
  }else{
    return result
  }
  });       
 
}



