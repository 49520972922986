import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import {getMethod } from "../../helpers/rest_api"

const Logout = props => {
  useEffect(() => {
    getMethod("user/logout").then(function(response) {
      console.log(response)
      if(response.status){
        localStorage.removeItem("authUser")
        localStorage.removeItem("authorizationToken")
        props.history.push("/login")
        }else{
        }
        
      })
    
  })

  return <></>
}

export default withRouter(Logout)
