import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash"
import {useParams ,Link, withRouter ,NavLink} from "react-router-dom"
import Select from "react-select";
import './styles.css';
import Attributes from './Attributes';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Alert,
  UncontrolledAlert
  
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import SweetAlert from "react-bootstrap-sweetalert"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {postMethod,getMethod,deleteMethod,updateStatusMethod } from "../../helpers/rest_api_watch"


const Home = props => {

  const [isLoading, setIsLoading] = useState(false);
  const [rows2, setrows2] = useState([])
  const [pvariations, setPvariations] = useState({})
  const [productVariations, setProductVariationsList] = useState([]);
  
  const [sucessMessage, setSucessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  
    const [brandId, setBrandId] = useState(props.location.pathname.split('/')[2])  
	const [modalId, setModalId] = useState(props.location.pathname.split('/')[4])
	const [materialId, setMaterialId] = useState(props.location.pathname.split('/')[6])  
	const [brandName, setBrandName] = useState('')
	const [modalName, setModalName] = useState('') 
	const [materialName, setMaterialName] = useState('') 
    const [variationstypes, setVariationsTypes] = useState([])
    const [activeTab, setActiveTab] = useState('Bracelet') 

    useEffect(() => {
      document.title = brandName+" "+modalName+" "+materialName+" Attributes - LuxyVerse Design Tool"
      },[brandName, modalName])

useEffect(() => {
     getBrandName() 
     getModalName()
     getMaterailName()
     
  },[brandId])
  
  function getBrandName(){
   getMethod('brands/getItem?id='+brandId).then(function(brand) {
     setBrandName(brand.item.title)
     }) 
  }
  
  function getModalName(){
   getMethod('modals/getModalsItem?id='+modalId).then(function(modal) {
     setModalName(modal.item.model_number)
     }) 
  }
  
  function getMaterailName(){
   getMethod('materials/getMaterialsItem?id='+materialId).then(function(material) {
     setMaterialName(material.item.title)
     }) 
  }

  function activateTab(tabName){
  setActiveTab(tabName)
  }
  
  return (
  
    <React.Fragment>
      <div className="page-content">
        {isLoading &&<LoadingSpinner />}
        <Breadcrumbs title={`${brandName} - ${modalName} - ${materialName}`} breadcrumbItem="Customize" />
		<Row className="tab_menu">
          <Col className={"custom_tab" + (activeTab=='Bracelet' ? ' active' : '')} xs={2} onClick={() => activateTab("Bracelet")}>Bracelet</Col>
          <Col className={"custom_tab" + (activeTab=='Bazel' ? ' active' : '')} xs={2} onClick={() => activateTab("Bazel")}>Bazel</Col>
          <Col className={"custom_tab" + (activeTab=='Dial' ? ' active' : '')} xs={2} onClick={() => activateTab("Dial")}>Dial</Col>
          <Col className={"custom_tab" + (activeTab=='Needle' ? ' active' : '')} xs={2} onClick={() => activateTab("Needle")}>Needle</Col>
       </Row>
        
        <Row>
        {activeTab =='Dial' &&<div>
        <Attributes 
        brandId={brandId}
		modalId={modalId}
		materialId={materialId}
		attr_type={'Dial'}
		/>
        </div>}
        
        {activeTab =='Bracelet' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		materialId={materialId}
		attr_type={'Bracelet'}
		/>
        </div>}
        
        {activeTab =='Bazel' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		materialId={materialId}
		attr_type={'Bazel'}
		/>
        </div>}
        {activeTab =='Needle' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		materialId={materialId}
		attr_type={'Needle'}
		/>
        </div>}
        </Row>
        
	  </div>
    </React.Fragment>
  )
}



export default Home


