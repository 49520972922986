import PropTypes from "prop-types"
import React, { useEffect,useState } from "react"
import { Row, Col, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withRouter, Link } from "react-router-dom"

import LoadingSpinner from "../../components/Common/LoadingSpinner"
import {postMethod } from "../../helpers/rest_api"
import logo from "../../assets/images/Monogram.png"
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const Register = props => {

 const [captchaInput, setCaptchaInput] = useState();
 const [isLoading, setIsLoading] = useState(false);
 const [emailError, setemailError] = useState('This field is required');
 const [usernameError, setusernameError] = useState('This field is required');
 const [isError, setError] = useState(false);
 const [CaptchaError, setCaptchaError] = useState('This field is required');
 const [ConPassError, setConPassError] = useState('This field is required');
 
      
 
 const [inputs, setInputs] = useState({username:'',email:'',password:'',con_password:'',user_captcha_value:''});
 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value.trim()}))
  }


  const userSignup = (event, values) => {
     
       if(inputs.password === inputs.con_password){
        if (validateCaptcha(inputs.user_captcha_input)==true) {
        setIsLoading(true)   
        console.log(inputs)
        postMethod("user/registerUser",inputs).then(function(response) {
        console.log(response)
        setIsLoading(false) 
          if(response.status){
            props.history.push("/login")
          }else{
           if(response.message.indexOf('already exists') > -1){
            
            if(response.constraint =='email'){
              setemailError('Oops! '+ response.constraint+' Already exist...')
            }else if(response.constraint =='username'){
              setusernameError('Oops! '+ response.constraint+' Already exist...')
            }else{
              setError('Oops! '+ response.constraint+' Already exist...')
            }
            setInputs(values => ({...values, [response.constraint]: ''}))
            
           } else{
            setError('Oops! '+ response.message)
           }
          }
        })
      }else{
        setCaptchaError('Oops! Captcha Invalid...')
        setInputs(values => ({...values, ['user_captcha_input']: ''}))
     }
      }else{
        setConPassError('Oops! Confirm password is not matching...')
        setInputs(values => ({...values, ['con_password']: ''}))
      }
      
    
  }

  useEffect(() => {
    loadCaptchaEnginge(6,'#125eb8','white'); 
  },[]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
        {isLoading &&<LoadingSpinner />}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Register</h5>
                    <p className="text-white-50 mb-0">to continue to Customizer</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">

                  <div className="p-2">
                      {isError ? (
                        <Alert color="danger">{isError}</Alert>
                      ) : null}

                      
                      <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        userSignup(e, v)
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          id="username"
                          name="username"
                          label="Username"
                          type="text"
                          required
                          placeholder="Enter username"
                          errorMessage={usernameError}
                          value={inputs.username}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          errorMessage={emailError}
                          value={inputs.email}
                          onChange={handleChange}
                        />
                      </div>
                      
 
                      
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                          value={inputs.password}
                          onChange={handleChange}
                        />
                      </div>
                      
                      <div className="mb-3">
                        <AvField
                          name="con_password"
                          label="Confirm Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                          value={inputs.con_password}
                          onChange={handleChange}
                          errorMessage={ConPassError}
                        />
                      </div>
                      
                      <div className="mb-3">
                        <AvField
                          type="checkbox"
                          className="form-check-input"
                          id="privacy_policy_excepted"
                          name="privacy_policy_excepted"
                          label="Terms and condition, privacy policy excepted"
                          required
                        />
                        
                      </div>
                      
                      
                      
                      
					  <div className="mb-2">
                     	 <LoadCanvasTemplate />
                      </div>
                      
                      <div className="mb-3">
                        <AvField
                          id="user_captcha_input"
                          name="user_captcha_input"
                          label=""
                          className="form-control"
                          placeholder="Enter captcha value"
                          type="text"
                          required
                          value={inputs.captchaInput}
                          onChange={handleChange}
                          errorMessage={CaptchaError}
                        />
                      </div>
                      <div className="mt-4">
                        
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
						 
						</div>

                      
                    </AvForm>
                      

                  </div>
                </div>
              </Card>
              <div className="mt-5 text-center">
                <p>Already have an account ? <a href="/login" className="fw-medium text-primary">
                  Login</a> </p>
               <p>© {new Date().getFullYear()} Customizer <i
                  className="mdi mdi-heart text-danger"></i> by  oCode.co
                        </p>
              </div>
            </Col>
          </Row>
          </Container>
      </div>
    </React.Fragment>
  )
}


export default (Register)
