import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
// Redux
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/Monogram.png"
import copyicon from "../../assets/images/copy-icon.webp"
// actions
import {postMethod } from "../../helpers/rest_api"
const UserProfile = props => {

  const[profile,setProfile] =useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
       const authUser = JSON.parse(localStorage.getItem("authUser"))
        setProfile(authUser) 
    }
  }, [])
  
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProfile(values => ({...values, [name]: value.trim()}))
  }
  
  
  function handleValidSubmit(event, values) {
  setIsLoading(true) 
  console.log(profile)
     postMethod("user/updateDetails",profile).then(function(response) {
		  setIsLoading(false) 
		    if(response.status){
				localStorage.setItem("authUser", JSON.stringify(profile))
				setSuccess('Profile Updated...')
			  }else{
			  setError(response.message)
			  }
		  })
		  
   // props.editProfile(values)
  }

  function copyContnet(textContent){
   var inp =document.createElement('input');
    document.body.appendChild(inp)
    inp.value =textContent
    inp.select();
    document.execCommand('copy',false);
    inp.remove();
    setSuccess('Copied to clipboard...')
    setTimeout(() => {
  			setSuccess(false)
	}, "2000")

  }
  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading &&<LoadingSpinner />}
          {/* Render Breadcrumb */}
          <Breadcrumb title="Customizer" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {isError ? (
                <Alert color="danger">{isError}</Alert>
              ) : null}
              {isSuccess ? (
                <Alert color="success">{isSuccess}</Alert>
              ) : null}
 
 
 			<Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="ms-3"></div>
                    <div className="flex-1 align-self-center">
                      <div className="text-muted">
                        <h5>{profile.username}</h5>
                        <p className="mb-1">{profile.email}</p>
						<p className="mb-0" >ALYIKEY: {profile.api_key} 
							<img
                        src={copyicon}
                        alt="Copy Contnet"
                        className="avatar-sm"
                        onClick={(e) => {
							  copyContnet(profile.api_key)
							}}
							
                      /></p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            
          </Row>

          <h4 className="card-title mb-4">Profile Detail</h4>
			<AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
          <Card>
            <CardBody>
              
              <Row>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="first_name"
                    label="First Name"
                    value={profile.first_name}
                    className="form-control"
                    placeholder="Enter first name"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="last_name"
                    label="Last Name"
                    value={profile.last_name}
                    className="form-control"
                    placeholder="Enter last_name"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="phone"
                    label="Phone Number"
                    value={profile.phone}
                    className="form-control"
                    placeholder="Enter phone"
                    type="tel"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="address"
                    label="Address"
                    value={profile.address}
                    className="form-control"
                    placeholder="Enter address"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="city"
                    label="City"
                    value={profile.city}
                    className="form-control"
                    placeholder="Enter city"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="state"
                    label="State"
                    value={profile.state}
                    className="form-control"
                    placeholder="Enter state"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="country"
                    label="Country"
                    value={profile.country}
                    className="form-control"
                    placeholder="Enter country"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="postal_code"
                    label="ZIP/Postal Code"
                    value={profile.postal_code}
                    className="form-control"
                    placeholder="Enter zip/postal code"
                    type="text"
                    onChange={handleChange}
                  />
                </Col>
                
                </Row>
                
              
            </CardBody>
          </Card>
          
            <h4 className="card-title mb-4">Store API Detail</h4>
               
          <Card>
            <CardBody>
              
              <Row>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="api_url"
                    label="API URL"
                    value={profile.api_url}
                    className="form-control"
                    placeholder="Enter API URL"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="api_consumer_key"
                    label="Consumer Key"
                    value={profile.api_consumer_key}
                    className="form-control"
                    placeholder="Enter Consumer Key"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="api_consumer_secret"
                    label="Consumer Secret"
                    value={profile.api_consumer_secret}
                    className="form-control"
                    placeholder="Enter Consumer Secret"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="api_access_token"
                    label="Access Token"
                    value={profile.api_access_token}
                    className="form-control"
                    placeholder="Enter Access Token"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                <Col lg="6" className="mb-2">
                  <AvField
                    name="api_access_token_secret"
                    label="Access Token Secret"
                    value={profile.api_access_token_secret}
                    className="form-control"
                    placeholder="Enter Access Token Secret"
                    type="text"
                    onChange={handleChange}
                    required
                  />
                </Col>
                
                
                
                </Row>
                
              
            </CardBody>
          </Card>
          <Card>
            <CardBody>
          <div className="mt-4">
                  <Button type="submit" color="danger">
                    Update
                  </Button>
                </div>
           </CardBody>
          </Card>
          
          </AvForm>
      </div>
    </React.Fragment>
  )
}




export default UserProfile