import PropTypes from 'prop-types'
import React, { useEffect,useState } from "react"

import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import {postMethod } from "../../helpers/rest_api"
import logo from "../../assets/images/Monogram.png"

const Login = (props) => {

const [isLoading, setIsLoading] = useState(false);
const [isError, setError] = useState(false);
const [isValidUserName, setValidUserName] = useState(false);
const [usernameError, setusernameError] = useState('This field is required');
const [passwordError, setPassError] = useState('This field is required');
const [inputs, setInputs] = useState({is_active:'1',username:'',password:'',userId:''});
 
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value.trim()}))
  }  
  
  // handleValidSubmit
  const validateUserName = (event, values) => {
		  setIsLoading(true) 
		  setError(false)
		  console.log(inputs)
		  postMethod("user/validateUserName",inputs).then(function(response) {
		  setIsLoading(false) 
		  console.log(response)
		  
      if(response.status && response.userId!=''){
        setValidUserName(true)
        setInputs(values => ({...values, ['userId']: response.userId}))
      }else{
        setusernameError(response.message)
        setInputs(values => ({...values, ['username']: ''}))
      } 
		  })
	}
  const validateUserNamePassword = (event, values) => {
    setIsLoading(true) 
    setError(false)
    console.log(inputs)
    postMethod("user/validateUserNamePassword",inputs).then(function(response) {
    setIsLoading(false) 
    console.log(response)
    if(response.status){
      let authUser = response.result
      localStorage.setItem("authUser", JSON.stringify(authUser))
      localStorage.setItem("OwnerKey", authUser.api_key)
      localStorage.setItem("authorizationToken", authUser.access_token)
      props.history.push("/brands-list")
      }else{
      setInputs(values => ({...values, ['password']: ''}))
      setPassError(response.message)
      }
      
    })
}

  

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
         {isLoading &&<LoadingSpinner />}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50 mb-0">Sign in to continue to Customizer.</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="60" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    
                      {isError ? (
                        <Alert color="danger">{isError}</Alert>
                      ) : null}

                      

                      {isValidUserName ?<>
                        <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        validateUserNamePassword(e, v)
                      }}
                      >
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Enter Password"
                          value={inputs.password}
                          onChange={handleChange}
                          type="password"
                          required
                          placeholder="password"
                          errorMessage={passwordError}
                        />
                      </div>
                      <div className="mt-3">
                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">
                          Log In
                        </button>
                      </div>
                      </AvForm>
                      </>:<>
                      <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        validateUserName(e, v)
                      }}
                      >
                        <div className="mb-3">
                        <AvField
                          name="username"
                          label="Enter Username"
                          value={inputs.username}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="username"
                          type="text"
                          required
                          errorMessage={usernameError}
                        />
                      </div>
                      <div className="mt-3">
                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">
                          Validate 
                        </button>
                      </div>
                      </AvForm>
                      </>}


                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted"><i
                          className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                      </div>
                    

                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>Don't have an account ? <Link to="/register"
                  className="fw-medium text-primary"> Signup now </Link> </p>
               <p>© {new Date().getFullYear()} Customizer <i
                  className="mdi mdi-heart text-danger"></i> by  oCode.co
                        </p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}


export default Login

