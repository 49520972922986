import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash"
import {useParams ,Link, withRouter ,NavLink} from "react-router-dom"
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert"
import { MDBDataTable } from "mdbreact"
import './styles.css';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Alert,
  UncontrolledAlert,
  Modal,
  
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {postMethod,getMethod,deleteMethod,updateStatusMethod,uploadfile } from "../../helpers/rest_api_watch"


const Attributes = props => {
  const [allDials, setDials] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [modal_standard, setmodal_standard] = useState(false)
  const [inputs, setInputs] = useState(
  {attr_type:props.attr_type,material_id:props.materialId,brand_id:props.brandId,model_id:props.modalId,image:'',thumbnail:'', price:'',title: '', offer_price: ''});
  
  
    const [CatalogId, setCatalogId] = useState("")

	const [confirm_alert, setconfirm_alert] = useState(false)
	const [success_dlg, setsuccess_dlg] = useState(false)


	const [dynamic_title, setdynamic_title] = useState("")
	const [dynamic_description, setdynamic_description] = useState("")
    const [Thumbnail, setThumbnail] = useState(false)
    const [featuredImage, setFeaturedImage] = useState(false)
    const [fileSize, setfileSize] = useState('200*200px')
    const [fileSizeW, setfileSizeW] = useState(200)
    const [fileSizeH, setfileSizeH] = useState(200)
    const [fileSizeError, setfileSizeError] = useState(false)
    
  
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value.trim()}))
  }
 
 function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  
  function handleAcceptedFiles(files,inputname) {
        setfileSizeError(false)
        let baseimg = new Image();
        var objectUrl = URL.createObjectURL(files[0]);
        baseimg.src= objectUrl
        baseimg.onload = function () {
            if(this.width < fileSizeW || this.height  <  fileSizeH){
               setfileSizeError(fileSizeW+'--'+fileSizeH+' Invalid Image Size... '+this.width+'::'+this.height)
            }else{
                setfileSizeError(this.width+'::'+this.height)
				let filesNameArray = files[0].name.split('_')
				let filesNameNew = filesNameArray[filesNameArray.length-1]
				let filesNameNewStr = filesNameNew.replace(/[0-9]/g, '')
				let filesNameNewArray = filesNameNewStr.split('.')
				let filesName = filesNameNewArray['0']
				if(filesName ==''){
				filesName = files[0].name
				}
				setInputs(values => ({...values, ['title']: filesName.replace(/[^\w\s]/gi, ' ')}))
				setFeaturedImage(files[0])
				setThumbnail(files[0])
        
            }
        };
        
  }
  
   
  
  
  function tog_standard() {
    setmodal_standard(!modal_standard)
    setThumbnail(false)
    setFeaturedImage(false)
    setInputs({attr_type:props.attr_type,material_id:props.materialId,brand_id:props.brandId,model_id:props.modalId,image:'',thumbnail:'', price:'',title: '', offer_price: ''})
  }
  
  
  useEffect(() => {
     getMethod('modals/getModalsItem?id='+props.modalId).then(function(modalData) {
     
     	if(modalData.status){
     	let modalDetail = modalData.item
     	  //console.log(props.attr_type)
     	  //console.log(modalDetail)
			if(props.attr_type =='Bracelet'){
			setfileSize(modalDetail.bracelet_width+'×'+modalDetail.bracelet_height+' px')
			setfileSizeW(modalDetail.bracelet_width)
			setfileSizeH(modalDetail.bracelet_height)
			}
			if(props.attr_type =='Bazel'){
			setfileSize(modalDetail.bazel_width+'×'+modalDetail.bazel_height+' px')
			setfileSizeW(modalDetail.bazel_width)
			setfileSizeH(modalDetail.bazel_height)
			}
			if(props.attr_type =='Dial'){
			setfileSize(modalDetail.dial_width+'×'+modalDetail.dial_height+' px')
			setfileSizeW(modalDetail.dial_width)
			setfileSizeH(modalDetail.dial_height)
			}
			if(props.attr_type =='Needle'){
			setfileSize(modalDetail.needle_width+'×'+modalDetail.needle_height+' px')
			setfileSizeW(modalDetail.needle_width)
			setfileSizeH(modalDetail.needle_height)
			}
     
     	
     	}else{
			if(props.attr_type =='Bracelet'){
			setfileSize('537×1024 px')
			setfileSizeW(537)
			setfileSizeH(1024)
			}
			if(props.attr_type =='Bazel'){
			setfileSize('922×913 px')
			setfileSizeW(922)
			setfileSizeH(913)
			}
			if(props.attr_type =='Dial'){
			setfileSize('719×714 px')
			setfileSizeW(719)
			setfileSizeH(714)
			}
			if(props.attr_type =='Needle'){
			setfileSize('257×253 px')
			setfileSizeW(257)
			setfileSizeH(253)
			}
     
     	}
     	
     	
     
     
     })
  },[props.modalId,props.attr_type])
  
  
  useEffect(() => {
     reloadListData()
  },[props.materialId])
  
  
  
  function reloadListData(){
  getMethod('attributes/getAttributesList?attr_type='+props.attr_type+'&material_id='+props.materialId+'&brand_id='+props.brandId+'&model_id='+props.modalId).then(function(attributesList) {
    //console.log('reloadListData')
    if(attributesList.results.length > 0){
    let catalogsList = attributesList.results
    catalogsList.map((item) => {
        item.id = item.id
        item.title = item.title
        item.is_active = (<div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
			{item.is_active ==1 &&<Button
			color="Success"
			className="btn btn-success font-size-12"
			onClick={() => updateCatStatus(item.id,0)}
			>
			Active
			</Button>}

			{item.is_active !=1 &&<Button
			color="Warning"
			className="btn btn-warning font-size-12"
			onClick={() => updateCatStatus(item.id,1)}
			>
			Disabled
			</Button>}
			</div>)
			
			item.action =(<div className="right-aligned">
			<Button
			color="primary"
			className="btn btn-primary waves-effect waves-light ml-1 mr-1"
			onClick={() => editPost(item)}
			>
			<i className="bx bxs-edit"></i>
			</Button>
			<Button
			color="danger"
			className="btn btn-danger waves-effect waves-light"
			onClick={() => deletePost(item.id)}
			>
			<i className="bx bx-trash-alt"></i>
			</Button>
			</div>)
			
			item.imageHtml =(<div className="right-aligned">
			<img
                                  data-dz-thumbnail=""
                                  width="60"
                                  height="60"
                                  className="avatar-el rounded object_fit"
                                  src={item.image}
                                />
			</div>)
			item.thumbnailHtml =(<div className="right-aligned">
			<img
                                  data-dz-thumbnail=""
                                  width="60"
                                  height="60"
                                  className="avatar-el rounded object_fit"
                                  src={item.thumbnail}
                                />
			</div>)
			
			
		})
		setDials(catalogsList)
		setIsLoading(false)
        }else{
        setIsLoading(false)
        }
        })
  }
  
  const data = {
    columns: [
      {
        label: "ID",
        field: "id"
      },
      {
        label: "Image",
        field: "imageHtml",
      },
      {
        label: "Name",
        field: "title",
      },
      {
        label: "Status",
        field: "is_active",
      },
      {
        label: "Action",
        field: "action",
      }
      
      
    ],
    rows: allDials,
  }
  
  let deletePost = (postId)  => {
    setconfirm_alert(true)
    setCatalogId(postId)
  }
  
  let editPost= (post)  => {
    setInputs(post)
    setThumbnail(false)
    setFeaturedImage(false)
    setmodal_standard(true)
  }
  let deletePostConfirmed = ()  => {
    let data ={id:CatalogId}
     deleteMethod('attributes/deleteAttributes',data).then(function(response) {
     setconfirm_alert(false)
	 setsuccess_dlg(true)
	 setdynamic_title("Deleted")
	 setdynamic_description("Your item has been deleted.")
	 setCatalogId('')
	 reloadListData()
    
    }) 
	
  }
  
  
  
  
  let updateCatStatus = (CatalogId,CatalogStatus) =>{
    let data ={id:CatalogId,is_active:CatalogStatus}
   //console.log(data)
    updateStatusMethod('attributes/updateAttributesStatus',data).then(function(response) {
     setconfirm_alert(false)
	 setsuccess_dlg(true)
	 setdynamic_title("Status Updated")
	 setdynamic_description("Your item status has been updated.")
	 setCatalogId('')
	 reloadListData() 
     }) 
     
  }
  
  async function uploadFile(imageFile){
        let uploadedfilePath = '';
        const data = new FormData();
        data.append('dataFile',imageFile ,imageFile.name);
        await uploadfile('banners/uploadfile/?dir=brands/'+inputs.brand_id+'/mo/'+inputs.model_id+'/ma/'+inputs.material_id+'/atr',data).then(function(uploadStatus) {
             if(uploadStatus.status){
			 uploadedfilePath  =uploadStatus.filePath ;
			 }
         })
         
         return uploadedfilePath;
          
  }
  
  function saveData(){
  if(inputs.offer_price>0){
       
	  }else{
		   inputs.offer_price=inputs.price
	  }
  if(inputs.thumbnail==''){
  inputs.thumbnail=inputs.image
  }
  
  //console.log(inputs)
  if(inputs.id){
       postMethod('attributes/updateAttributesItem',inputs).then(function(result) {
       setIsLoading(false)
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Updated")
	   setdynamic_description("Your item has been updated.")
	 
      })
       }else{
       postMethod('attributes/addAttributesItem',inputs).then(function(result) {
       setIsLoading(false)
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Added")
	   setdynamic_description("Your item has been added.")
	 
      })
      }
      
  }
  
  
  async function submitForm(){
    var d1 = document.getElementsByClassName("is-invalid")
     if(d1.length ==0){
       setIsLoading(true)
       if(featuredImage){
       inputs.image=await uploadFile(featuredImage)
       }
       if(Thumbnail){
       inputs.thumbnail=await uploadFile(Thumbnail)
       }
       saveData()
       
     }
  } 
  
  
  return (
        
        <Row>
          <Col className="col-12">
          {isLoading &&<LoadingSpinner />}
          <div className="text-right">
            <Link to={`/brands-list`} className="btn btn-secondary mr-1  mb-2">
              Back to brands
              </Link>
		          <a onClick={() => {tog_standard()}} className="btn btn-primary waves-effect waves-light mb-2" >
		              Add New
              </a>
        </div>
        
            <Card>
              <CardBody>
                  
                  <MDBDataTable
                  responsive 
                  striped 
                  bordered 
                  hover
                  noBottomColumns 
                  data={data}
                  disableRetreatAfterSorting={true}
       			  />

              </CardBody>
            </Card>
          </Col>
          
          {confirm_alert ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmButtonText="Yes, delete it!"
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        deletePostConfirmed()
                      }}
                      onCancel={() => setconfirm_alert(false)}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}
        {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          
          
          
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">Attribute Details</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <AvForm className="needs-validation" onSubmit={e => {
                      submitForm()
                    }}>
                      <div className="modal-body">
                     
                     <Row>
					  <Col lg={6}>
                      

                      <div className="mb-3">
                      <Label htmlFor="description">Image (Min: {fileSize}):</Label>
                        <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles,'image')
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            {featuredImage ?<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded object_fit"
                                  src={URL.createObjectURL(featuredImage)}
                                />
                            </div>:<>
                            {inputs.image =='' &&<i className="display-4 text-muted mdi mdi-upload-network-outline"></i>}
                            {inputs.image &&<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded  object_fit"
                                  src={inputs.image}
                                />
                            </div>}
                            </>}
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                     	
					
					  </div>
					  
					  </Col>
					  <Col lg={6}>
					  

                     </Col>
					  </Row>
					  {fileSizeError &&<Row>
					  <Col lg={12}>
					  <p className="filesizeerror">{fileSizeError}</p>
					  </Col>
					  </Row>}
					    
                     <Row>
                      <Col lg={12}>
                      <div className="mb-3">
                       
                    
                     <Label htmlFor="title">Title : </Label>
                        <AvField
                          errorMessage="Enter Title"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Enter Title..."
                          value={inputs.title}
                          onChange={handleChange}
                        />
                        
                      </div>
                      
                      <div className="mb-3">
                        <Label htmlFor="model_price">Price : </Label>
                        <AvField
                          errorMessage="Enter Price"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="price"
                          name="price"
                          placeholder="Enter Price..."
                          value={inputs.price}
                          onChange={handleChange}
                        />
                        
                    
                      </div>
                      
                      <div className="mb-3">
                        <Label htmlFor="offer_price">Offer Price :</Label>
                        <AvField
                          errorMessage="Enter Offer Price"
                          className="form-control"
                          validate={{ required: { value: false } }}
                          type="text"
                          id="offer_price"
                          name="offer_price"
                          placeholder="Enter Offer Price..."
                          value={inputs.offer_price}
                          onChange={handleChange}
                        />
                        
                      </div>
                      
                      </Col>
                      
					  
					  </Row>
					  
                       
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard()
                          }}
                          className="btn btn-primary waves-effect"
                          data-dismiss="modal"
                        >
                          Cancel
                            </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Submit
                            </button>
                      </div>
                       </AvForm>
                    </Modal>
                    
        </Row>
  )
}



export default Attributes


