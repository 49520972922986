import React from "react"
import "./spinner.css";
import {
  Spinner,
  
} from "reactstrap"
const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <Spinner className="m-1 spinner" color="primary" />
    </div>
  )
}



export default LoadingSpinner
