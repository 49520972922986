import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash"
import { Link, withRouter ,NavLink} from "react-router-dom"
import classNames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert"


import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Alert,
  UncontrolledAlert,
  Modal,
  
} from "reactstrap"


import { MDBDataTable } from "mdbreact"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import Dropzone from "react-dropzone"

//import ReactApexChart from "react-apexcharts"

import {postMethod,getMethod,deleteMethod,updateStatusMethod ,uploadfile} from "../../helpers/rest_api"



const List = props => {

const [modal_standard, setmodal_standard] = useState(false)
const [inputs, setInputs] = useState({brand_id:props.location.pathname.split('/')[2],model_id:props.location.pathname.split('/')[4],thumbnail:'',title: '', description: '',price:'', offer_price: ''});
  
const [isLoading, setIsLoading] = useState(true);
const [sucessMessage, setSucessMessage] = useState(false);
const [errorMessage, setErrorMessage] = useState(false);
const [CatalogId, setCatalogId] = useState("")

const [confirm_alert, setconfirm_alert] = useState(false)
const [success_dlg, setsuccess_dlg] = useState(false)
const [error_dlg, seterror_dlg] = useState(false)


const [dynamic_title, setdynamic_title] = useState("")
const [dynamic_description, setdynamic_description] = useState("")
const [catalogs, setCatalogs] = useState()
const [brandId, setBrandId] = useState(props.location.pathname.split('/')[2])  
const [modalId, setModalId] = useState(props.location.pathname.split('/')[4])   
const [brandName, setBrandName] = useState('')
const [modalName, setModalName] = useState('') 
const [Thumbnail, setThumbnail] = useState(false)  
  


useEffect(() => {
  document.title =brandName+" "+modalName+" - LuxyVerse Design Tool"
},[brandName,modalName])


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value.trim()}))
  }
 
  function handleAcceptedFiles(files,inputname) {
        
        let filesNameArray = files[0].name.split('_')
        let filesNameNew = filesNameArray[filesNameArray.length-1]
        let filesNameNewStr = filesNameNew.replace(/[0-9]/g, '')
        let filesNameNewArray = filesNameNewStr.split('.')
        let filesName = filesNameNewArray['0']
        if(filesName ==''){
         filesName = files[0].name
        }
        setInputs(values => ({...values, ['title']: filesName.replace(/[^\w\s]/gi, ' ')}))
        setThumbnail(files[0])
  }
  
  
  function tog_standard() {
    setmodal_standard(!modal_standard)
    setThumbnail(false)
    setInputs({brand_id:props.location.pathname.split('/')[2],model_id:props.location.pathname.split('/')[4],thumbnail:'',title: '', description: '',price:'', offer_price: ''})
  }
  
  let editPost= (post)  => {
    setInputs(post)
    setThumbnail(false)
    setmodal_standard(true)
  }
  
   async function uploadFile(imageFile){
        let uploadedfilePath = '';
        const data = new FormData();
        data.append('dataFile',imageFile ,imageFile.name);
        await uploadfile('banners/uploadfile/?dir=brands/'+inputs.brand_id+'/mo/'+inputs.model_id+'/ma',data).then(function(uploadStatus) {
             if(uploadStatus.status){
			 uploadedfilePath  =uploadStatus.filePath ;
			 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(uploadStatus.message)
			 }
         })
         
         return uploadedfilePath;
          
  }
  
  async function submitForm(){
    var d1 = document.getElementsByClassName("is-invalid")
     if(d1.length ==0){
       setIsLoading(true)
       if(Thumbnail){
       inputs.thumbnail=await uploadFile(Thumbnail)
       }
       saveData()
       
     }
  } 
   
  function saveData(){
    
if(inputs.offer_price>0){
       
	  }else{
		   inputs.offer_price=inputs.price
	  }
       if(inputs.id){
       postMethod('products/updateProductsItem',inputs).then(function(result) {
       setIsLoading(false)
       if(result.status){
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Updated")
	   setdynamic_description("Your item has been updated.")
	   }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(result.message)
			 }
	 
      })
       }else{
       postMethod('products/addProductsItem',inputs).then(function(result) {
       setIsLoading(false)
       if(result.status){
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Added")
	   setdynamic_description("Your item has been added.")
	   }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(result.message)
			 }
	 
      })
      }
     
     
	 
  }
  
  useEffect(() => {
     getBrandName() 
     getModalName() 
     reloadListData()
  },[brandId])
  
  function getBrandName(){
   getMethod('brands/getItem?id='+brandId).then(function(brand) {
     setBrandName(brand.item.title)
     }) 
  }
  
  function getModalName(){
   getMethod('modals/getModalsItem?id='+modalId).then(function(modal) {
     setModalName(modal.item.title)
     }) 
  }
  
  function reloadListData(){
  getMethod('products/getProductsList?brand_id='+brandId+'&model_id='+modalId).then(function(productsList) {
    //console.log(productsList)
    setIsLoading(false)
  	if(productsList.status){
  	let catalogsList = productsList.results
    catalogsList.map((item) => {
      console.log(item)
        item.id = item.id
        item.title = item.title
        item.is_active = (<div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
			{item.is_active ==1 &&<Button
			color="Success"
			className="btn btn-success font-size-12"
			onClick={() => updateCatStatus(item.id,0)}
			>
			Active
			</Button>}

			{item.is_active !=1 &&<Button
			color="Warning"
			className="btn btn-warning font-size-12"
			onClick={() => updateCatStatus(item.id,1)}
			>
			Disabled
			</Button>}
			</div>)
			item.thumbnailHtml =(<div className="right-aligned">
			<img
                                  data-dz-thumbnail=""
                                  width="60"
                                  height="60"
                                  className="avatar-el rounded object_fit"
                                  src={item.thumbnail}
                                />
			</div>)
			item.action =(<div className="right-aligned">
			<Button
			color="primary"
			className="btn btn-primary waves-effect waves-light ml-1 mr-1"
			onClick={() => editPost(item)}
			>
			<i className="bx bxs-edit"></i>
			</Button>
			<Button
			color="danger"
			className="btn btn-danger waves-effect waves-light"
			onClick={() => deletePost(item.id)}
			>
			<i className="bx bx-trash-alt"></i>
			</Button>
			
			
			
			
			
			</div>)
		})
		setCatalogs(catalogsList)
        }else{
		setErrorMessage(productsList.error?productsList.error:productsList.message)
		}
        })
  }
  
  
  let deletePost = (postId)  => {
    setconfirm_alert(true)
    setCatalogId(postId)
  }
  
  
  let deletePostConfirmed = ()  => {
    let data ={id:CatalogId}
     deleteMethod('products/deleteProducts',data).then(function(response) {
     setconfirm_alert(false)
	 if(response.status){
     
     setsuccess_dlg(true)
	 setdynamic_title("Deleted")
	 setdynamic_description("Your item has been deleted.")
	 setCatalogId('')
	 reloadListData()
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(response.message)
			 }
    
    }) 
	
  }
  
  
  let updateCatStatus = (CatalogId,CatalogStatus) =>{
    let data ={id:CatalogId,is_active:CatalogStatus}
   //console.log(data)
    updateStatusMethod('products/updateProductsStatus',data).then(function(response) {
     setconfirm_alert(false)
	 if(response.status){
	 setsuccess_dlg(true)
	 setdynamic_title("Status Updated")
	 setdynamic_description("Your item status has been updated.")
	 setCatalogId('')
	 reloadListData() 
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(response.message)
			 }
     }) 
     
  }
  
  
  
  
  
 
 const data = {
    columns: [
      {
        label: "ID",
        field: "id"
      },
      {
        label: "Thumbnail",
        field: "thumbnailHtml",
      },
      {
        label: "Name",
        field: "title",
      },
      {
        label: "Status",
        field: "is_active",
      },
      {
        label: "Action",
        field: "action",
      }
      
      
    ],
    rows: catalogs,
  }
  
  
  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading &&<LoadingSpinner />}
        <Breadcrumbs title={`${brandName} - ${modalName}`} breadcrumbItem="Products List" />
        <div className="text-right">
        <Link to={`/tshirt-brands`} className="btn btn-secondary mr-1  mb-2">
		      Back to brands
		    </Link>
		<a onClick={() => {tog_standard()}}   className="btn btn-primary waves-effect waves-light mb-2" >
		Add New
        </a>
        </div>
        
        {errorMessage!='' &&<Row>
        <Col className="col-12">
        <p className="alert alert-danger">{errorMessage}</p>
        </Col>
        </Row>}
        
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                  
                  <MDBDataTable
                  responsive 
                  striped 
                  bordered 
                  hover
                  noBottomColumns 
                  data={data}
                  disableRetreatAfterSorting={true}
       			  />

              </CardBody>
            </Card>
          </Col>
        </Row>
       
        {confirm_alert ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmButtonText="Yes, delete it!"
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        deletePostConfirmed()
                      }}
                      onCancel={() => setconfirm_alert(false)}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}
        {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          
          {error_dlg ? (
            <SweetAlert
              error
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
                  
      </div>




                    
                    
<Modal
  isOpen={modal_standard}
  toggle={() => {
	tog_standard()
  }}
>
  <div className="modal-header">
	<h5 className="modal-title mt-0" id="myModalLabel">Attribute Details</h5>
	<button
	  type="button"
	  onClick={() => {
		setmodal_standard(false)
	  }}
	  className="close"
	  data-dismiss="modal"
	  aria-label="Close"
	>
	  <span aria-hidden="true">&times;</span>
	</button>
  </div>
  <AvForm className="needs-validation" onSubmit={e => {
  submitForm()
}}>
  <div className="modal-body">

 <Row>
  <Col lg={6}>


  <div className="mb-3">
  <Label htmlFor="description">Thumbnail (200×200px) :</Label>
	<Dropzone
onDrop={acceptedFiles => {
  handleAcceptedFiles(acceptedFiles,'thumbnail')
}}
>
{({ getRootProps, getInputProps }) => (
  <div className="dropzone dz-clickable">
	<div
	  className="dz-message needsclick"
	  {...getRootProps()}
	>
	  <input {...getInputProps()} />
	  <div className="mb-3">
		{Thumbnail ?<div className="align-catalogs-center">
			<img
			  data-dz-thumbnail=""
			  width="100"
			  height="100"
			  className="avatar-el rounded object_fit "
			  src={URL.createObjectURL(Thumbnail)}
			/>
		</div>:<>
		{inputs.thumbnail =='' &&<i className="display-4 text-muted mdi mdi-upload-network-outline"></i>}
		{inputs.thumbnail &&<div className="align-catalogs-center">
			<img
			  data-dz-thumbnail=""
			  width="100"
			  height="100"
			  className="avatar-el rounded  object_fit"
			  src={inputs.thumbnail}
			/>
		</div>}
		</>}
	  </div>
	</div>
  </div>
)}
</Dropzone>


  </div>

  </Col>

  </Row>

 <Row>
  <Col lg={12}>
  <div className="mb-3">


 <Label htmlFor="title">Title : </Label>
	<AvField
	  errorMessage="Enter Title"
	  className="form-control"
	  validate={{ required: { value: true } }}
	  type="text"
	  id="title"
	  name="title"
	  placeholder="Enter Title..."
	  value={inputs.title}
	  onChange={handleChange}
	/>

  </div>

  <div className="mb-3">
	<Label htmlFor="model_price">Price : </Label>
	<AvField
	  errorMessage="Enter Price"
	  className="form-control"
	  validate={{ required: { value: true } }}
	  type="text"
	  id="price"
	  name="price"
	  placeholder="Enter Price..."
	  value={inputs.price}
	  onChange={handleChange}
	/>


  </div>

  <div className="mb-3">
	<Label htmlFor="offer_price">Offer Price :</Label>
	<AvField
	  errorMessage="Enter Offer Price"
	  className="form-control"
	  validate={{ required: { value: false } }}
	  type="text"
	  id="offer_price"
	  name="offer_price"
	  placeholder="Enter Offer Price..."
	  value={inputs.offer_price}
	  onChange={handleChange}
	/>

  </div>

  <div className="mb-3">
	<Label htmlFor="description">Description :</Label>
	<AvField
	  errorMessage="Enter Description"
	  className="form-control"
	  type="textarea"
	  id="description"
	  name="description"
	  rows="3"
	  value={inputs.description}
	  onChange={handleChange}
	/>


  </div>

  </Col>


  </Row>


  </div>
  <div className="modal-footer">
	<button
	  type="button"
	  onClick={() => {
		tog_standard()
	  }}
	  className="btn btn-primary waves-effect"
	  data-dismiss="modal"
	>
	  Cancel
		</button>
	<button
	  type="submit"
	  className="btn btn-primary waves-effect waves-light"
	>
	  Submit
		</button>
  </div>
   </AvForm>
</Modal>
    </React.Fragment>
  )
}



export default List
