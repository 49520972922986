import * as config  from "./config"

export async function uploadfile(functionname,data) {

const APIoptions = {
             method: 'POST',
             headers: {
                 'Accept': 'application/json'
             },
             body: data
};
        
 return fetch(config.rest_api_host+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         return result
  });       
 
}


export async function postMethod(functionname,data) {

const APIoptions = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("OwnerKey"),
                Authorizationtoken: localStorage.getItem("authorizationToken")
            }),
            body: JSON.stringify(data)
};
        
 return fetch(config.rest_api_host+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         return result
  });       
 
}


export async function getMethod(functionname) {
const APIoptions = {
            method: 'GET',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("OwnerKey"),
                Authorizationtoken: localStorage.getItem("authorizationToken")
            })
};
 //console.log(localStorage.getItem("OwnerKey"))       
 return fetch(config.rest_api_host+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         return result
  });       
 
}


export async function deleteMethod(functionname,data) {
const APIoptions = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("OwnerKey"),
                Authorizationtoken: localStorage.getItem("authorizationToken")
            }),
            body: JSON.stringify(data)
};
        
 return fetch(config.rest_api_host+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         return result
  });       
 
}


export async function updateStatusMethod(functionname,data) {
const APIoptions = {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem("OwnerKey"),
                Authorizationtoken: localStorage.getItem("authorizationToken")
            }),
            body: JSON.stringify(data)
};
        
 return fetch(config.rest_api_host+functionname, APIoptions)
 .then((response) => response.json())
 .then((result) => {
         return result
  });       
 
}



