import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash"
import { Link, withRouter ,NavLink} from "react-router-dom"
import classNames from "classnames"
import SweetAlert from "react-bootstrap-sweetalert"


import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Alert,
  UncontrolledAlert,
  Modal,
  
} from "reactstrap"


import { MDBDataTable } from "mdbreact"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import Dropzone from "react-dropzone"

//import ReactApexChart from "react-apexcharts"

import {postMethod,getMethod,deleteMethod,updateStatusMethod ,uploadfile} from "../../helpers/rest_api"

import { SketchPicker } from 'react-color'


const List = props => {
          
const [modal_standard, setmodal_standard] = useState(false)
const [inputs, setInputs] = useState({
brand_id:props.location.pathname.split('/')[2],
model_id:props.location.pathname.split('/')[4],
product_id:props.location.pathname.split('/')[6],
side_id:props.location.pathname.split('/')[8],
type:'image',title: '', price:'', offer_price: '',color:'',color_options:'',editable_box:'',src:'',x:'',y:''});
  
const [isLoading, setIsLoading] = useState(true);
const [sucessMessage, setSucessMessage] = useState(false);
const [errorMessage, setErrorMessage] = useState(false);
const [CatalogId, setCatalogId] = useState("")

const [confirm_alert, setconfirm_alert] = useState(false)
const [success_dlg, setsuccess_dlg] = useState(false)
const [error_dlg, seterror_dlg] = useState(false)
const [ShowSketchPicker, setShowSketchPicker] = useState(false) 


const [dynamic_title, setdynamic_title] = useState("")
const [dynamic_description, setdynamic_description] = useState("")
const [catalogs, setCatalogs] = useState()
const [brandId, setBrandId] = useState(props.location.pathname.split('/')[2])  
const [modalId, setModalId] = useState(props.location.pathname.split('/')[4])
const [productId, setproductId] = useState(props.location.pathname.split('/')[6])
const [sideId, setsideId] = useState(props.location.pathname.split('/')[8])   
const [brandName, setBrandName] = useState('')
const [modalName, setModalName] = useState('') 
const [productName, setProductName] = useState() 
const [sideName, setSideName] = useState() 
const [Thumbnail, setThumbnail] = useState(false) 

  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value.trim()}))
  }
 
  
  function tog_standard() {
    setmodal_standard(!modal_standard)
    setInputs({
brand_id:props.location.pathname.split('/')[2],
model_id:props.location.pathname.split('/')[4],
product_id:props.location.pathname.split('/')[6],
side_id:props.location.pathname.split('/')[8],
type:'',title: '', price:'', offer_price: '',x:'',y:'',h:'',color:'',color_options:'',editable_box:'',src:'',path:'',x:'',y:''})
  }
  
  let editPost= (post)  => {
  console.log(post)
    setInputs(post)
    setmodal_standard(true)
  }
  
   async function uploadFile(imageFile){
        let uploadedfilePath = '';
        const data = new FormData();
        data.append('dataFile',imageFile ,imageFile.name);
        await uploadfile('banners/uploadfile',data).then(function(uploadStatus) {
        console.log(uploadStatus)
             if(uploadStatus.status){
			        uploadedfilePath  =uploadStatus.filePath ;
			   }
         })
         
         return uploadedfilePath;
          
  }
  
  async function submitForm(){
    var d1 = document.getElementsByClassName("is-invalid")
     if(d1.length ==0){
       //setIsLoading(true)
       console.log(inputs)
       if(inputs.type =='image' && Thumbnail){
        inputs.src= await uploadFile(Thumbnail)
       }
       saveData()
       
     }
  } 
   
  function saveData(){
    
    if(inputs.offer_price>0){
       
	  }else{
		   inputs.offer_price=inputs.price
	  }
       if(inputs.id){
       postMethod('templates/updateTemplateItem',inputs).then(function(result) {
       setIsLoading(false)
       if(result.status){
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Updated")
	   setdynamic_description("Your item has been updated.")
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(result.message)
			 }
	 
      })
       }else{
       //console.log(inputs)
       postMethod('templates/addTemplateItem',inputs).then(function(result) {
       //console.log(result)
       setIsLoading(false)
       if(result.status){
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Added")
	   setdynamic_description("Your item has been added.")
	   }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(result.message)
			 }
	 
      })
      }
     
     
	 
  }
  
  useEffect(() => {
     getBrandName() 
     getModalName()
     getProductName()
     getSideName() 
     reloadListData()
  },[sideId])
  
  function getBrandName(){
   getMethod('brands/getItem?id='+brandId).then(function(brand) {
     setBrandName(brand.item.title)
     }) 
  }
  
  function getModalName(){
   getMethod('modals/getModalsItem?id='+modalId).then(function(modal) {
     setModalName(modal.item.title)
     }) 
  }
  
  function getProductName(){
   getMethod('products/getProductsItem?id='+productId).then(function(product) {
     setProductName(product.item.title)
     }) 
  }
  
  function getSideName(){
   getMethod('sides/getSidesItem?id='+sideId).then(function(side) {
     setSideName(side.item.title)
     }) 
  }
  
  
  
  function reloadListData(){
  getMethod('templates/getTemplatesList?brand_id='+brandId+'&model_id='+modalId+'&product_id='+productId+'&side_id='+sideId).then(function(layerList) {
    setIsLoading(false)
        
    if(layerList.status){
    let catalogsList = layerList.results
    catalogsList.map((item) => {
        item.id = item.id
        item.title = item.title
        item.is_active = (<div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
			{item.is_active ==1 &&<Button
			color="Success"
			className="btn btn-success font-size-12"
			onClick={() => updateCatStatus(item.id,0)}
			>
			Active
			</Button>}

			{item.is_active !=1 &&<Button
			color="Warning"
			className="btn btn-warning font-size-12"
			onClick={() => updateCatStatus(item.id,1)}
			>
			Disabled
			</Button>}
			</div>)
			
			item.action =(<div className="right-aligned">
			
			<Button
			color="primary"
			className="btn btn-primary waves-effect waves-light ml-1 mr-1"
			onClick={() => editPost(item)}
			>
			<i className="bx bxs-edit"></i>
			</Button>
			<Button
			color="danger"
			className="btn btn-danger waves-effect waves-light"
			onClick={() => deletePost(item.id)}
			>
			<i className="bx bx-trash-alt"></i>
			</Button>
			</div>)
		})
		setCatalogs(catalogsList)
        }else{
		setErrorMessage(layerList.error?layerList.error:layerList.message)
		}
        })
  }
  
  
  let deletePost = (postId)  => {
    setconfirm_alert(true)
    setCatalogId(postId)
  }
  
  let deletePostConfirmed = ()  => {
    let data ={id:CatalogId}
     deleteMethod('templates/deleteTemplate',data).then(function(response) {
     setconfirm_alert(false)
	 if(response.status){
	 setsuccess_dlg(true)
	 setdynamic_title("Deleted")
	 setdynamic_description("Your item has been deleted.")
	 setCatalogId('')
	 reloadListData()
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(response.message)
			 }
    
    }) 
	
  }
  
  
  let updateCatStatus = (CatalogId,CatalogStatus) =>{
    let data ={id:CatalogId,is_active:CatalogStatus}
   //console.log(data)
    updateStatusMethod('templates/updateTemplateStatus',data).then(function(response) {
     setconfirm_alert(false)
	 if(response.status){
	 setsuccess_dlg(true)
	 setdynamic_title("Status Updated")
	 setdynamic_description("Your item status has been updated.")
	 setCatalogId('')
	 reloadListData() 
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(response.message)
			 }
     }) 
     
  }
  
  
  
  
  
 
 const data = {
    columns: [
      {
        label: "ID",
        field: "id"
      },
      {
        label: "Name",
        field: "title",
      },
      {
        label: "Status",
        field: "is_active",
      },
      {
        label: "Action",
        field: "action",
      }
      
      
    ],
    rows: catalogs,
  }
  
  function handleAcceptedFiles(files) {
    setThumbnail(files[0])
}
  const handleColorChange = ({ hex })=>{
    setShowSketchPicker(false)
    //console.log(hex)
    setInputs(values => ({...values, ['color']: hex}))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading &&<LoadingSpinner />}
        <Breadcrumbs title={`${brandName} - ${modalName} - ${productName} - ${sideName}`} breadcrumbItem="Layers List" />
        <div className="text-right">
			
			<Link to={`/tshirts-brand/${brandId}/modal/${modalId}/product/${productId}/sides`} className="btn btn-secondary mr-1  mb-2">
			Back to sides
			</Link>
			
			<a onClick={() => {tog_standard()}}   className="btn btn-primary waves-effect waves-light mb-2" >
			Add New
			</a>
			
        </div>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                  
                  <MDBDataTable
                  responsive 
                  striped 
                  bordered 
                  hover
                  noBottomColumns 
                  data={data}
                  disableRetreatAfterSorting={true}
       			  />

              </CardBody>
            </Card>
          </Col>
        </Row>
       
        {confirm_alert ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmButtonText="Yes, delete it!"
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        deletePostConfirmed()
                      }}
                      onCancel={() => setconfirm_alert(false)}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}
        {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          
          {error_dlg ? (
            <SweetAlert
              error
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
                  
      </div>


                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">Attribute Details</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <AvForm className="needs-validation" onSubmit={e => {
                      submitForm()
                    }}>
                      <div className="modal-body">
                     
                     <Row>
                      <Col lg={12}>
                      <div className="mb-3">
                       
                    
                     <Label htmlFor="title">Title : </Label>
                        <AvField
                          errorMessage="Enter Title"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Enter Title..."
                          value={inputs.title}
                          onChange={handleChange}
                        />
                        
                      </div>
                      
                      <div className="mb-3">
                        <Label htmlFor="model_price">Type : </Label>
                        <select name="type" className="form-control" value={inputs.type} 
                         onChange={
                        (e) => setInputs(values => (
                        {...values, ['type']: e.target.value }
                        ))}>
                        <option value="">Select</option>
                        <option value="image">Image</option>
                        <option value="text">Text</option>
                        </select>
  
                      </div>
                      
                      
                      {inputs.type =='text'?<div className="mb-3">
                        <Label htmlFor="model_price">Text : </Label>
                        <AvField
                          errorMessage="Enter Text"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="src"
                          name="src"
                          placeholder="Enter Text..."
                          value={inputs.src}
                          onChange={handleChange}
                        />
                      </div>:<div className="mb-3">
                        <Label htmlFor="src">Src : </Label>
                        <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                           {Thumbnail ?<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded object_fit"
                                  src={URL.createObjectURL(Thumbnail)}
                                />
                            </div>:<>
                            {inputs.src =='' &&<i className="display-4 text-muted mdi mdi-upload-network-outline"></i>}
                            {inputs.src &&<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded  object_fit"
                                  src={inputs.src}
                                />
                            </div>}
                            </>}
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                      </div>}
                      
                      
                       
                       
                       <Row>
                      <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="offer_price">Position X :</Label>
                        <AvField
                          errorMessage="Enter Position"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="number"
                          id="x"
                          name="x"
                          placeholder="X..."
                          value={inputs.x}
                          onChange={handleChange}
                        />
                        
                      </div>
                      </Col>
                      <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="offer_price">Position Y :</Label>
                        <AvField
                          errorMessage="Enter Position"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="number"
                          id="y"
                          name="y"
                          placeholder="y..."
                          value={inputs.y}
                          onChange={handleChange}
                        />
                        
                      </div>
                      </Col>
                      <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="offer_price">Box Position :</Label>
                        <AvField
                          errorMessage="Enter"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="editable_box"
                          name="editable_box"
                          placeholder="Enter..."
                          value={inputs.editable_box}
                          onChange={handleChange}
                        />
                        
                      </div>
                      </Col>
                      
                      <Col md={3}>
                       <div className="mb-3">
                        <Label htmlFor="offer_price">Color :</Label>
                        <AvField
                          errorMessage="Enter Color"
                          className="form-control"
                          validate={{ required: { value: false } }}
                          type="text"
                          id="color"
                          name="color"
                          placeholder="color..."
                          value={inputs.color}
                          onClick={(e)=> setShowSketchPicker(!ShowSketchPicker)}
                        />
                        
                      </div>
                      {ShowSketchPicker &&<div style={{position: "absolute",zIndex: 9,}} ><SketchPicker color={inputs.color} onChangeComplete={ handleColorChange }/> </div>}
                      
                      </Col>
                      </Row>
                      <div className="mb-3">
                        <Label htmlFor="color_options">Color Options (", " seprated) :</Label>
                        <AvField
                          errorMessage="Enter Color 0ptions"
                          className="form-control"
                          validate={{ required: { value: false } }}
                          type="textarea"
                          id="color_options"
                          name="color_options"
                          rows="2"
                          value={inputs.color_options}
                          onChange={handleChange}
                        />
                      </div>
                       <Row>
                       <Col md={6}>
                       <div className="mb-3">
                        <Label htmlFor="model_price">Price : </Label>
                        <AvField
                          errorMessage="Enter Price"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="price"
                          name="price"
                          placeholder="Enter Price..."
                          value={inputs.price}
                          onChange={handleChange}
                        />
                        
                    
                      </div>
                      </Col>
                      
                      <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="offer_price">Offer Price :</Label>
                        <AvField
                          errorMessage="Enter Offer Price"
                          className="form-control"
                          validate={{ required: { value: false } }}
                          type="text"
                          id="offer_price"
                          name="offer_price"
                          placeholder="Enter Offer Price..."
                          value={inputs.offer_price}
                          onChange={handleChange}
                        />
                        
                      </div>
                      </Col>
                      </Row>
                      
                      </Col>
                      
					  
					  </Row>
					  
                      </div>
                      
                      
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard()
                          }}
                          className="btn btn-primary waves-effect"
                          data-dismiss="modal"
                        >
                          Cancel
                            </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Submit
                            </button>
                      </div>
                       </AvForm>
                    </Modal>
    </React.Fragment>
  )
}



export default List
