import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { isEmpty, map, size } from "lodash"
import {useParams ,Link, withRouter ,NavLink} from "react-router-dom"
import Select from "react-select";
import './styles.css';
import Attributes from './Attributes';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Alert,
  UncontrolledAlert
  
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import SweetAlert from "react-bootstrap-sweetalert"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {postMethod,getMethod,deleteMethod,updateStatusMethod } from "../../helpers/rest_api"


const Home = props => {

  const [isLoading, setIsLoading] = useState(false);
  const [rows2, setrows2] = useState([])
  const [pvariations, setPvariations] = useState({})
  const [productVariations, setProductVariationsList] = useState([]);
  
  const [sucessMessage, setSucessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  
    const [brandId, setBrandId] = useState(props.location.pathname.split('/')[2])  
	const [modalId, setModalId] = useState(props.location.pathname.split('/')[4])
	const [productId, setproductId] = useState(props.location.pathname.split('/')[6])  
	const [brandName, setBrandName] = useState('')
	const [modalName, setModalName] = useState('') 
	const [materialName, setMaterialName] = useState('') 
    const [variationstypes, setVariationsTypes] = useState([])
    const [activeTab, setActiveTab] = useState('Front') 


    useEffect(() => {
      document.title =brandName+" "+modalName+" "+materialName+" - LuxyVerse Design Tool"
    },[brandName,modalName,materialName])

useEffect(() => {
     getBrandName() 
     getModalName()
     getMaterailName()
     
  },[brandId])
  
  function getBrandName(){
   getMethod('brands/getItem?id='+brandId).then(function(brand) {
     setBrandName(brand.item.title)
     }) 
  }
  
  function getModalName(){
   getMethod('modals/getModalsItem?id='+modalId).then(function(modal) {
     setModalName(modal.item.title)
     }) 
  }
  
  function getMaterailName(){
     getMethod('products/getProductsItem?id='+productId).then(function(material) {
     setMaterialName(material.item.title)
     }) 
  }

  function activateTab(tabName){
  setActiveTab(tabName)
  }
  
  return (
  
    <React.Fragment>
      <div className="page-content">
        {isLoading &&<LoadingSpinner />}
        <Breadcrumbs title={`${brandName} - ${modalName} - ${materialName}`} breadcrumbItem="Product Sides" />
		<Row className="tab_menu">
          <Col className={"custom_tab" + (activeTab=='Front' ? ' active' : '')} xs={2} onClick={() => activateTab("Front")}>Front Side</Col>
          <Col className={"custom_tab" + (activeTab=='Back' ? ' active' : '')} xs={2} onClick={() => activateTab("Back")}>Back Side</Col>
          <Col className={"custom_tab" + (activeTab=='FrontTilted' ? ' active' : '')} xs={2} onClick={() => activateTab("FrontTilted")}>Front Tilted</Col>
          <Col className={"custom_tab" + (activeTab=='BackTilted' ? ' active' : '')} xs={2} onClick={() => activateTab("BackTilted")}>Back Tilted</Col>
          <Col className={"custom_tab" + (activeTab=='Left' ? ' active' : '')} xs={2} onClick={() => activateTab("Left")}>Left Side</Col>
          <Col className={"custom_tab" + (activeTab=='Right' ? ' active' : '')} xs={2} onClick={() => activateTab("Right")}>Right Side</Col>
       </Row>
        
        <Row>
        {activeTab =='Left' &&<div>
        <Attributes 
        brandId={brandId}
		modalId={modalId}
		productId={productId}
		title={'Left'}
		/>
        </div>}
        
        {activeTab =='Front' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		productId={productId}
		title={'Front'}
		/>
        </div>}
        
        {activeTab =='Back' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		productId={productId}
		title={'Back'}
		/>
        </div>}

        {activeTab =='FrontTilted' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		productId={productId}
		title={'FrontTilted'}
		/>
        </div>}
        
        {activeTab =='BackTilted' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		productId={productId}
		title={'BackTilted'}
		/>
        </div>}

        
        {activeTab =='Right' &&<div>
        <Attributes  
        brandId={brandId}
		modalId={modalId}
		productId={productId}
		title={'Right'}
		/>
        </div>}

        
        
        </Row>
        
	  </div>
    </React.Fragment>
  )
}



export default Home


