import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash"
import {useParams ,Link, withRouter ,NavLink} from "react-router-dom"
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert"
import { MDBDataTable } from "mdbreact"
import './styles.css';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Alert,
  UncontrolledAlert,
  Modal,
  
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import LoadingSpinner from "../../components/Common/LoadingSpinner"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {postMethod,getMethod,deleteMethod,updateStatusMethod,uploadfile } from "../../helpers/rest_api"


const Attributes = props => {
  const [allDials, setDials] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [modal_preview, setmodal_preview] = useState(false)

  
  const [errorMessage, setErrorMessage] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false)
  const [inputs, setInputs] = useState(
  {product_id:props.productId,brand_id:props.brandId,model_id:props.modalId,preview_thumbnail:'',base_thumbnail:'',title: props.title,description:'',editable_box:'',price:'',offer_price:''});
  
  
    const [CatalogId, setCatalogId] = useState("")

	const [confirm_alert, setconfirm_alert] = useState(false)
	const [success_dlg, setsuccess_dlg] = useState(false)
    const [error_dlg, seterror_dlg] = useState(false)
	const [dynamic_title, setdynamic_title] = useState("")
	const [dynamic_description, setdynamic_description] = useState("")
    
    const [prevThumbnail, setprevThumbnail] = useState(false)
    const [baseThumbnail, setbaseThumbnail] = useState(false)
    
    
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value.trim()}))
  }
 
 function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  
  function handleAcceptedFiles(files,inputname) {
  
			if(inputname == 'prevThumbnail'){
			  setprevThumbnail(files[0])
			}else{
			  setbaseThumbnail(files[0])
			}
					
  }
  
   
  
  
  function tog_standard() {
    setmodal_standard(!modal_standard)
    setprevThumbnail(false)
    setbaseThumbnail(false)
    setInputs({product_id:props.productId,brand_id:props.brandId,model_id:props.modalId,preview_thumbnail:'',base_thumbnail:'',title: props.title,description:'',editable_box:'',price:'',offer_price:''})
  }
  
  
  useEffect(() => {
     reloadListData()
  },[props.productId])
  
  
  let PreviewProduct=(postId)  => {
    setCatalogId(postId)
    setmodal_preview(true)
  }
  
  
  function reloadListData(){
  
  getMethod('sides/getSidesList?title='+props.title+'&product_id='+props.productId+'&brand_id='+props.brandId+'&model_id='+props.modalId).then(function(sideList) {
    //console.log('reloadListData')
    if(sideList.status){
    if(sideList.results.length > 0){
    let catalogsList = sideList.results
    catalogsList.map((item) => {
        item.id = item.id
        item.title = item.title
        item.is_active = (<div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
			{item.is_active ==1 &&<Button
			color="Success"
			className="btn btn-success font-size-12"
			onClick={() => updateCatStatus(item.id,0)}
			>
			Active
			</Button>}

			{item.is_active !=1 &&<Button
			color="Warning"
			className="btn btn-warning font-size-12"
			onClick={() => updateCatStatus(item.id,1)}
			>
			Disabled
			</Button>}
			</div>)
			
			item.action =(<div className="right-aligned">
			<Link
			color="primary"
			className="btn btn-primary waves-effect waves-light ml-1 mr-1"
			to={`/tshirts-brand/${props.brandId}/modal/${props.modalId}/product/${props.productId}/side/${item.id}/layers`}
			
			>
			<i className="bx bxs-edit"></i> Layers
			</Link>
			
			<Link
			color="primary"
			className="btn btn-primary waves-effect waves-light ml-1 mr-1"
			to={`/tshirts-brand/${props.brandId}/modal/${props.modalId}/product/${props.productId}/side/${item.id}/templates`}
			
			>
			<i className="bx bxs-edit"></i> Templates
			</Link>
			
			<Button
			color="primary"
			className="btn btn-primary waves-effect waves-light ml-1 mr-1"
			onClick={() => editPost(item)}
			>
			<i className="bx bxs-edit"></i>
			</Button>
			<Button
			color="danger"
			className="btn btn-danger waves-effect waves-light"
			onClick={() => deletePost(item.id)}
			>
			<i className="bx bx-trash-alt"></i>
			</Button>
			<Button
			color="primary"
			className="btn btn-danger waves-effect waves-light ml-1 mr-1"
			onClick={() => PreviewProduct(item.id)}
			>
			Preview
			</Button>
			
			</div>)
			
			item.imageHtml =(<div className="right-aligned">
			<img
                                  data-dz-thumbnail=""
                                  width="60"
                                  height="60"
                                  className="avatar-el rounded object_fit"
                                  src={item.preview_thumbnail}
                                />
			</div>)
			
			
			
		})
		setDials(catalogsList)
		setIsLoading(false)
        }else{
        setDials()
        setIsLoading(false)
        }
        }else{
		setErrorMessage(sideList.error?sideList.error:sideList.message)
		}
        })
  }
  
  const data = {
    columns: [
      {
        label: "Preview Image",
        field: "imageHtml",
      },
      {
        label: "Action",
        field: "action",
      }
      
      
    ],
    rows: allDials,
  }
  
  let deletePost = (postId)  => {
    setconfirm_alert(true)
    setCatalogId(postId)
  }
  
  let editPost= (post)  => {
    setInputs(post)
    setprevThumbnail(false)
    setbaseThumbnail(false)
    setmodal_standard(true)
  }
  let deletePostConfirmed = ()  => {
    let data ={id:CatalogId}
     deleteMethod('sides/deleteSide',data).then(function(response) {
     setconfirm_alert(false)
	 if(response.status){
	 setsuccess_dlg(true)
	 setdynamic_title("Deleted")
	 setdynamic_description("Your item has been deleted.")
	 setCatalogId('')
	 reloadListData()
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(response.message)
			 }
    
    }) 
	
  }
  
  
  
  
  let updateCatStatus = (CatalogId,CatalogStatus) =>{
    let data ={id:CatalogId,is_active:CatalogStatus}
    updateStatusMethod('sides/updateSideStatus',data).then(function(response) {
     setconfirm_alert(false)
	 if(response.status){
	 setsuccess_dlg(true)
	 setdynamic_title("Status Updated")
	 setdynamic_description("Your item status has been updated.")
	 setCatalogId('')
	 reloadListData() 
	 }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(response.message)
			 }
     }) 
     
  }
  
  async function uploadFile(imageFile){
        let uploadedfilePath = '';
        const data = new FormData();
        data.append('dataFile',imageFile ,imageFile.name);
        await uploadfile('banners/uploadfile/?dir=brands/'+inputs.brand_id+'/mo/'+inputs.model_id+'/ma/'+inputs.material_id+'/atr',data).then(function(uploadStatus) {
             if(uploadStatus.status){
			 uploadedfilePath  =uploadStatus.filePath ;
			 }
         })
         
         return uploadedfilePath;
          
  }
  
  function saveData(){
  if(inputs.id){
       postMethod('sides/updateSideItem',inputs).then(function(result) {
       //console.log(result)
       setIsLoading(false)
       if(result.status){
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Updated")
	   setdynamic_description("Your item has been updated.")
	   }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(result.message)
			 }
	 
      })
       }else{
       postMethod('sides/addSideItem',inputs).then(function(result) {
       setIsLoading(false)
       if(result.status){
       reloadListData()
       tog_standard()
       setconfirm_alert(false)
	   setsuccess_dlg(true)
	   setdynamic_title("Added")
	   setdynamic_description("Your item has been added.")
	   }else{
			 setconfirm_alert(false)
			 seterror_dlg(true)
		 	 setdynamic_title("Oops!")
		 	 setdynamic_description(result.message)
			 }
	 
      })
      }
      
  }
  
  
  async function submitForm(){
    var d1 = document.getElementsByClassName("is-invalid")
     if(d1.length ==0){
       setIsLoading(true)
       if(prevThumbnail){
       inputs.preview_thumbnail=await uploadFile(prevThumbnail)
       }
       if(baseThumbnail){
       inputs.base_thumbnail=await uploadFile(baseThumbnail)
       }
       saveData()
       
     }
  } 
  
  
  return (
        
        <>
        <Row>
          <Col className="col-12">
          {isLoading &&<LoadingSpinner />}
          <div className="text-right">
            <Link to={`/tshirt-brands`} className="btn btn-secondary mr-1  mb-2">
              Back to brands
            </Link>
		  {allDials ?<></>:<a onClick={() => {tog_standard()}} className="btn btn-primary waves-effect waves-light mb-2" >
		    Add New
           </a>}
        </div>
        </Col>
        </Row>
        
        {errorMessage!='' &&<Row>
        <Col className="col-12">
        <p className="alert alert-danger">{errorMessage}</p>
        </Col>
        </Row>}
        
        <Row>
        <Col className="col-12">
            <Card>
              <CardBody>
                  
                  <MDBDataTable
                  responsive 
                  striped 
                  bordered 
                  hover
                  noBottomColumns 
                  data={data}
                  disableRetreatAfterSorting={true}
       			  />

              </CardBody>
            </Card>
          </Col>
          
          {confirm_alert ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmButtonText="Yes, delete it!"
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        deletePostConfirmed()
                      }}
                      onCancel={() => setconfirm_alert(false)}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}
        {success_dlg ? (
            <SweetAlert
              success
              title={dynamic_title}
              onConfirm={() => {
                setsuccess_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          
          {error_dlg ? (
            <SweetAlert
              error
              title={dynamic_title}
              onConfirm={() => {
                seterror_dlg(false)
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
          
          
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard()
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">Attribute Details</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <AvForm className="needs-validation" onSubmit={e => {
                      submitForm()
                    }}>
                      <div className="modal-body">
                     
                     <Row>
					  <Col lg={6}>
                      

                      <div className="mb-3">
                      <Label htmlFor="description">Preview Image:</Label>
                        <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles,'prevThumbnail')
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            {prevThumbnail ?<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded object_fit"
                                  src={URL.createObjectURL(prevThumbnail)}
                                />
                            </div>:<>
                            {inputs.preview_thumbnail =='' &&<i className="display-4 text-muted mdi mdi-upload-network-outline"></i>}
                            {inputs.preview_thumbnail &&<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded  object_fit"
                                  src={inputs.preview_thumbnail}
                                />
                            </div>}
                            </>}
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                     	
					
					  </div>
					  
					  </Col>
					  <Col lg={6}>
                      

                      <div className="mb-3">
                      <Label htmlFor="description">Base Image:</Label>
                        <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles,'baseThumbnail')
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            {baseThumbnail ?<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded object_fit"
                                  src={URL.createObjectURL(baseThumbnail)}
                                />
                            </div>:<>
                            {inputs.base_thumbnail =='' &&<i className="display-4 text-muted mdi mdi-upload-network-outline"></i>}
                            {inputs.base_thumbnail &&<div className="align-catalogs-center">
                                <img
                                  data-dz-thumbnail=""
                                  width="100"
                                  height="100"
                                  className="avatar-el rounded  object_fit"
                                  src={inputs.base_thumbnail}
                                />
                            </div>}
                            </>}
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                     	
					
					  </div>
					  
					  </Col>
					  </Row>
					 
					 
					 
					    
                     <Row>
                      <Col lg={12}>
                      
                      
                      <div className="mb-3">
                        <Label htmlFor="model_price">Price : </Label>
                        <AvField
                          errorMessage="Enter Price"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          type="text"
                          id="price"
                          name="price"
                          placeholder="Enter Price..."
                          value={inputs.price}
                          onChange={handleChange}
                        />
                        
                    
                      </div>
                      
                      <div className="mb-3">
                        <Label htmlFor="offer_price">Offer Price :</Label>
                        <AvField
                          errorMessage="Enter Offer Price"
                          className="form-control"
                          validate={{ required: { value: false } }}
                          type="text"
                          id="offer_price"
                          name="offer_price"
                          placeholder="Enter Offer Price..."
                          value={inputs.offer_price}
                          onChange={handleChange}
                        />
                        
                      </div>
                      
                      
                      <div className="mb-3">
                        <Label htmlFor="offer_price">EditAble Box :(width,height,left,top)</Label>
                        <AvField
                          errorMessage="Enter EditAble Box size"
                          className="form-control"
                          validate={{ required: { value: false } }}
                          type="text"
                          id="editable_box"
                          name="editable_box"
                          placeholder="Enter EditAble Box size"
                          value={inputs.editable_box}
                          onChange={handleChange}
                        />
                        
                      </div>
                      
                      </Col>
                      
					  
					  </Row>
					  
                       
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard()
                          }}
                          className="btn btn-primary waves-effect"
                          data-dismiss="modal"
                        >
                          Cancel
                            </button>
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Submit
                            </button>
                      </div>
                       </AvForm>
                    </Modal>
                    
                    <Modal size="lg" isOpen={modal_preview}
toggle={() => {
setmodal_preview(false)
}}
>


<div className="modal-body">
<iframe style={{width: '100%',height: '550px'}}  src={'https://www.psd2htmlx.com/dsb/pro/polo/?product_id='+props.productId}></iframe>
</div>

</Modal>


                    
        </Row>
        </>
  )
}



export default Attributes


